import { MouseEventHandler } from 'react';
import {
    AppBar as MuiAppBar,
    Toolbar,
    IconButton,
    Typography,
    styled,
    Box,
    AppBarProps as MuiAppBarProps
} from '@mui/material';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { signOut } from 'firebase/auth';
import { auth } from '../config/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import AuthActions from 'redux/actions/AuthActions';
import AuthActionCreators from 'redux/creators/AuthActionCreators';
import CurrentUserNavBarItem from './CurrentUserNavBarItem';
import { LOG_TYPES } from 'utils/Logger';
import ClinicalServicesDropdown from './ClinicalServicesDropdown';
import { RootState } from 'redux/reducers';
import { useLocation } from 'react-router-dom';
import RegionFlagComponent from './RegionFlagComponent';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const drawerWidth = 240;

interface TopBarProps {
    open: boolean;
    onDrawerOpen: MouseEventHandler;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));

const TopBar = (props: TopBarProps) => {
    const { open, onDrawerOpen } = props;
    const clinician = useSelector((state: RootState) => state.AuthReducer.user);
    const permissions = useSelector((state: RootState) => state.AuthReducer.permissions);

    const region = useSelector((state: RootState) => state.AuthReducer.currentRegion);

    const dispatch = useDispatch();

    const location = useLocation();
    const { pathname } = location;

    const handleLogout = async () => {
        try {
            dispatch(
                AuthActionCreators.logForAudit({
                    target: {
                        id: clinician.id,
                        description: 'Logged off platform',
                        affectedUser: clinician.email
                    },
                    type: LOG_TYPES.READ
                })
            );

            signOut(auth);
            dispatch({ type: AuthActions.SIGN_OUT });
        } catch (e: any) {
            console.log(e);
        }
    };

    const showClinicalServicesDropdown = () => {
        if (pathname.match('/dashboard/access/patient/')) {
            return false;
        }
        return permissions.includes('internal_clinical_services');
    };

    return (
        <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onDrawerOpen}
                    edge="start"
                    sx={{ marginRight: 5, ...(open && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap>
                    Phio Portal
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {showClinicalServicesDropdown() && <ClinicalServicesDropdown />}
                <RegionFlagComponent region={region} />
                <CurrentUserNavBarItem />
                <IconButton onClick={handleLogout} color="inherit">
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    open: PropTypes.bool,
    onDrawerOpen: PropTypes.func
};

export default TopBar;
